






























































import { Component, Vue } from "vue-property-decorator";
import { REST } from "../_util/rest_call";
import { GlobalSnackBarFn } from "../_util/rest_call";
import router from "../router";
import store from "../store";

@Component({
  name: "Login",
  data() {
      return {
          loginName: "",
          password: "",
          show_pwd: false,
    };
  },
  computed: {
    emptyUserName() {
      return this.$data.loginName.trim().length == 0;
    }
  },
  methods: {
      registerUser() {
        REST.logDebug("registerUser");
        router.push({ path: "/register"});
      },
      forgotPassword() {
        REST.logDebug("forgotPassword");
        router.push({ path: "/forgotPassword"});
      },
      loginUser() {
          const loginTxt = this.$data.loginName;
          const passwordTxt = this.$data.password;
          this.$data.loginName = "";
          this.$data.password = "";
          REST.call("POST", "/users/login",
                        { "login":loginTxt, "password":passwordTxt },
                        (r => {
                            REST.logDebug("Login:", r);
                            REST.setLogonData(r.detail);
                            document.cookie = "session_token=" + REST.wsSessionToken();
                            router.push({ path: "/"});
                            return r.details;
                        }),
                        (async (result) => { 
                            GlobalSnackBarFn("Login Failed!\n" + result.toString());
                            REST.setLogonData(null);
                        }));
      }
  }
})
export default class Login extends Vue {}

